// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const getAllPermissionOfUser = (profile) => {
  let list = [];

  if (profile.data.roles) {
    list = profile.data.roles.data
      .map((item) => item.permissions.data)
      .flat();
  }

  if (profile.data.permissions) {
    list = [...list, ...profile.data.permissions.data];
  }

  return list;
};

const initialState = {
  //
  isGetProfileUserRequest: false,
  isGetProfileUserSuccess: false,
  isGetProfileUserFailure: false,
  permissions: [],
  profileUser: null,
  //
  isUpdateInformationUserRequest: false,
  isUpdateInformationUserSuccess: false,
  isUpdateInformationUserFailure: false,
  //
  isGetAllUsersRequest: false,
  isGetAllUsersSuccess: false,
  isGetAllUsersFailure: false,
  getAllUsersState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get profile user
    [Actions.getProfileUserRequest]: (state) => ({
      ...state,
      isGetProfileUserRequest: true,
      isGetProfileUserSuccess: false,
      isGetProfileUserFailure: false,
    }),
    [Actions.getProfileUserSuccess]: (state, { payload }) => ({
      ...state,
      isGetProfileUserRequest: false,
      isGetProfileUserSuccess: true,
      isGetProfileUserFailure: false,
      permissions: getAllPermissionOfUser(payload),
      profileUser: payload,
    }),
    [Actions.getProfileUserFailure]: (state, { payload }) => ({
      ...state,
      isGetProfileUserRequest: false,
      isGetProfileUserSuccess: false,
      isGetProfileUserFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetProfileUserState]: (state) => ({
      ...state,
      isGetProfileUserRequest: false,
      isGetProfileUserSuccess: false,
      isGetProfileUserFailure: false,
      profileUser: null,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update information user
    [Actions.updateInformationUserRequest]: (state) => ({
      ...state,
      isUpdateInformationUserRequest: true,
      isUpdateInformationUserSuccess: false,
      isUpdateInformationUserFailure: false,
    }),
    [Actions.updateInformationUserSuccess]: (state) => ({
      ...state,
      isUpdateInformationUserRequest: false,
      isUpdateInformationUserSuccess: true,
      isUpdateInformationUserFailure: false,
    }),
    [Actions.updateInformationUserFailure]: (state, { payload }) => ({
      ...state,
      isUpdateInformationUserRequest: false,
      isUpdateInformationUserSuccess: false,
      isUpdateInformationUserFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateInformationUser]: (state) => ({
      ...state,
      isUpdateInformationUserRequest: false,
      isUpdateInformationUserSuccess: false,
      isUpdateInformationUserFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get all users
    [Actions.getAllUsersRequest]: (state) => ({
      ...state,
      isGetAllUsersRequest: true,
      isGetAllUsersSuccess: false,
      isGetAllUsersFailure: false,
    }),
    [Actions.getAllUsersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllUsersRequest: false,
      isGetAllUsersSuccess: true,
      isGetAllUsersFailure: false,
      getAllUsersState: payload,
    }),
    [Actions.getAllUsersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllUsersRequest: false,
      isGetAllUsersSuccess: false,
      isGetAllUsersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Reset
    [Actions.resetUserState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
